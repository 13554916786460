<template>
  <div class="box">
    <p class="title">注册账号</p>
    <a-spin :spinning="loading">
      <div class="bgs">
        <a-form-model
          ref="register"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="tradeUsername">
            <a-input
              v-model="form.tradeUsername"
              size="large"
              placeholder="请输入您的用户名"
            />
          </a-form-model-item>
          <a-form-model-item prop="tradePhone">
            <a-input
              v-model="form.tradePhone"
              size="large"
              placeholder="请输入您的手机号"
            />
          </a-form-model-item>
          <div class="sendmsg tips">
            <a-form-model-item prop="code">
              <a-input
                class="inputs"
                size="large"
                v-model="form.code"
                placeholder="请输入验证码"
              ></a-input>
            </a-form-model-item>
            <!-- <a-button
              type="primary"
              size="large"
              class="sendcode"
              @click="getVerificationCode"
              >获取验证码</a-button
            > -->
            <VerifiyCodeButton
              :mobile="form.tradePhone"
              :serverError="serverError"
              @onSendMessage="getVerificationCode"
            />
          </div>
          <a-form-model-item prop="tradePassword">
            <a-input
              v-model="form.tradePassword"
              size="large"
              type="password"
              placeholder="请设置您的密码"
              onkeyup="this.value=this.value.replace(/[, ]/g,'')"
            />
          </a-form-model-item>
          <a-form-model-item prop="tradePasswordagain">
            <a-input
              v-model="form.tradePasswordagain"
              size="large"
              type="password"
              placeholder="请再次输入您的密码"
              onkeyup="this.value=this.value.replace(/[, ]/g,'')"
            />
          </a-form-model-item>
          <a-button
            class="putBTN"
            size="large"
            type="primary"
            :loading="loading"
            @click="onSubmit"
            >确认</a-button
          >
          <!-- <p class="bottomtips">
            <a @click="loginRoute">使用已有帐户登录</a>
          </p> -->
        </a-form-model>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { LoginAPI } from "@/api/login.js";
// import { tips } from "@/utils/tips.js";
import Routerpush from "@/mixins/Routerpush";
import VerifiyCodeButton from "@/components/verification-code-button";
// import { rules } from "@/utils/rules.js";
export default {
  mixins: [Routerpush],
  components: {
    VerifiyCodeButton,
  },
  data() {
    return {
      form: {
        tradeUsername: "",
        tradePhone: "",
        tradePassword: "",
        tradePasswordagain: "",
        code: null,
      },
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      rules: {
        tradeUsername: [
          { required: true, message: "请输入您的用户名" },
          {
            min: 3,
            message: "用户名至少3位",
            trigger: "blur",
          },
          {
            max: 32,
            message: "用户名至多32位",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              let reg = new RegExp(
                "[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]"
              );
              if (reg.test(value)) {
                return callback("不能输入该特殊字符");
              } else {
                return callback();
              }
            },
          },
        ],
        tradePhone: [
          { required: true, message: "请输入您的手机号" },
          {
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
            message: "手机号码格式不正确,请检查",
          },
        ],
        code: [{ required: true, message: "请输入验证码" }],
        tradePassword: [
          { required: true, message: "请输入您的密码" },
          {
            min: 8,
            max: 20,
            message: "密码至少8位，至多20位",
            trigger: "blur",
          },
          // {
          //   pattern: /^[A-Z][A-z0-9]*$/,
          //   message: "首字符必须为大写字母",
          // },
          {
            validator: (rule, value, callback) => {
              let reg = new RegExp(
                "[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]"
              );
              if (reg.test(value)) {
                return callback("密码不能输入该特殊字符");
              } else {
                return callback();
              }
            },
          },
        ],
        tradePasswordagain: [
          { required: true, message: "请再次输入您的密码" },
          // {
          //   min: 8,
          //   max: 20,
          //   message: "密码至少8位，至多32位",
          //   trigger: "blur",
          // },
          // {
          //   pattern: /^[A-Z][A-z0-9]*$/,
          //   message: "首字符必须为大写字母,且不能有特殊字符",
          // },
          // {
          //   validator: (rule, value, callback) => {
          //     let reg = new RegExp(
          //       "[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]"
          //     );
          //     if (reg.test(value)) {
          //       return callback("密码不能输入该特殊字符");
          //     } else {
          //       return callback();
          //     }
          //   },
          // },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback("请再一次输入密码");
              } else if (value !== this.form.tradePassword) {
                return callback("两次密码不一致");
              } else {
                return callback();
              }
            },
          },
        ],
      },
      loading: false,
      serverError: true,
    };
  },

  mounted() {},

  methods: {
    register() {
      this.$router.push("/register");
    },
    onSubmit() {
      let that = this;
      this.$refs.register.validate((valid) => {
        if (valid) {
          this.loading = true;
          LoginAPI.register(this.form).then((res) => {
            this.loading = false;
            // tips(res, "修改成功");
            console.log(res);
            if (res.success) {
              setTimeout(function () {
                that.$router.push("/success?type=1");
              }, 500);
            }
          });
        }
      });
    },
    // 获取验证码
    getVerificationCode() {
      console.log("发送验证码");
      LoginAPI.getRegisterCode(this.form.tradePhone).then((res) => {
        console.log(res.success);
        if (res.success) {
          this.serverError = true;
        } else {
          console.log("sdfsdfsdgfdg");
          this.serverError = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 490px;
  height: 530px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  margin-top: 100px;
}
.title {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}
.bgs {
  & > * {
    margin-bottom: 20px;
  }
  background: #fff;
  height: 490px;
  box-sizing: border-box;
  padding: 30px 50px;
  text-align: left;
  .tips {
    display: flex;
    justify-content: space-between;
    .inputs {
      width: 140%;
    }
    .sendcode {
      margin-left: 10px;
    }
  }
}
.putBTN {
  background: rgba(255, 70, 0, 1);
  width: 100%;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  margin-top: 50px;
}
.bottomtips {
  text-align: right;
  margin-top: 10px;
  & > a {
    color: rgba(255, 70, 0, 1);
    font-size: 14px;
  }
}
</style>
